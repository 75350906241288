@media (min-width: 768px) {
  .evnt-card {
    width: 57.6rem;
    height: 20rem;
    border: 0.1rem solid #707c8c;
    padding: 1.4rem 2rem;
    background: #ffffff;
    margin-bottom: 2rem;
  }
  .evnt-card .evntcard-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 175.02%;
    color: #707c8c;
    margin-bottom: 1.8rem;
  }
  .evnt-card a {
    text-decoration: underline;
    color: #707c8c;
  }
  .evnt-card .evntcard-para {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 130.02%;
    color: #707c8c;
    margin-bottom: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .evnt-card .evntcard-lower {
    width: 53.3rem;
    display: flex;
    justify-content: space-between;
  }
  .evnt-card .evntcard-lower .evntcard-logo {
    display: flex;
    align-items: center;
  }
  .evnt-card .evntcard-lower .evntcard-logo img {
    margin-right: 0.5rem;
  }
  .evnt-card .evntcard-lower .evntcard-logo p {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 175.02%;
    color: #707c8c;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .evnt-card {
    width: 32rem;
    height: 100%;
    border: 0.1rem solid #707c8c;
    padding: 2.5rem 1.5rem;
    background: #ffffff;
    margin-bottom: 4rem;
  }
  .evnt-card a {
    text-decoration: none;
  }
  .evnt-card .evntcard-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 150%;
    color: #707c8c;
    margin-bottom: 1.5rem;
  }
  .evnt-card a {
    text-decoration: underline;
    color: #707c8c;
  }
  .evnt-card .evntcard-para {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 175.02%;
    color: #707c8c;
    margin-bottom: 2.6rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .evnt-card .evntcard-lower .evntcard-logo {
    display: flex;
    align-items: center;
  }
  .evnt-card .evntcard-lower .evntcard-logo img {
    margin-right: 0.5rem;
  }
  .evnt-card .evntcard-lower .evntcard-logo p {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 175.02%;
    color: #707c8c;
  }
}/*# sourceMappingURL=EvntCard.css.map */