@media (min-width: 768px) {
  .nav {
    width: 100%;
    height: 7.5rem;
    background: #ffffff;
    display: flex;
    box-shadow: 0px 4px 8px 0px rgba(0, 153, 0, 0.03);
  }
  .nav .navbar {
    width: 90%;
    max-width: 117.6rem;
    height: 4.5rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav .navbar .nav-logo {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 175.02%;
  }
  .nav .navbar .nav-logo a {
    text-decoration: none;
    color: #ff9900;
  }
  .nav .navbar .hamburger-menu {
    display: none;
  }
  .nav .navbar .nav-items {
    width: 57.5rem;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    align-items: baseline;
  }
  .nav .navbar .nav-items .nav_items_link {
    width: 43.7rem;
    display: flex;
    justify-content: space-between;
  }
  .nav .navbar .nav-items .dropdown {
    display: block;
    position: relative;
    z-index: 1000;
  }
  .nav .navbar .nav-items .dropdown .dropbtn {
    background: #ff9900;
    color: white;
    width: 9.5rem;
    height: 3.5rem;
    font-size: 1.6rem;
    font-weight: 300;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
  }
  .nav .navbar .nav-items .dropdown .dropdown-content {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 9.5rem;
    padding: 0.5rem 0rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
  }
  .nav .navbar .nav-items .dropdown .dropdown-content a {
    color: black;
    padding: 1rem 1.2rem;
    text-decoration: none;
    display: block;
  }
  .nav .navbar .nav-items a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #707c8c;
  }
  .nav .navbar .nav-items a:hover {
    color: #009900;
  }
  .nav .navbar .nav-btn {
    width: 9.5rem;
    height: 3.5rem;
    background: #ff9900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav .navbar .nav-btn a {
    color: #ffffff !important;
  }
  .nav .navbar .nav-items .active {
    color: #009900;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .nav {
    width: 100%;
    height: 7.5rem;
    background: #ffffff;
    display: flex;
  }
  .nav .navbar {
    width: 90%;
    height: 4.5rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav .navbar .nav-logo {
    font-weight: 700;
    font-size: 2rem;
    line-height: 175.02%;
  }
  .nav .navbar .nav-logo a {
    text-decoration: none;
    color: #ff9900;
  }
  .nav .navbar .hamburger-menu label {
    font-size: 3rem;
  }
  .nav .navbar .hamburger-menu input {
    display: none;
  }
  .nav .navbar .nav-items {
    display: none;
  }
}/*# sourceMappingURL=Navbar.css.map */