// Media Queries

//  Computers and Laptops

@media (min-width: 768px) {
  // .active{
  //   color: #009900;
  // }

  .nav {
    width: 100%;
    height: 7.5rem;
    background: #ffffff;
    display: flex;
    box-shadow: 0px 4px 8px 0px rgba(0, 153, 0, 0.03);

    .navbar {
      width: 90%;
      max-width: 117.6rem;
      height: 4.5rem;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-logo {
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 175.02%;

        a {
          text-decoration: none;
          color: #ff9900;
        }
      }

      .hamburger-menu {
        display: none;
      }

      .nav-items {
        width: 57.5rem;
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        align-items: baseline;

        .nav_items_link {
          width: 43.7rem;
          display: flex;
          justify-content: space-between;
        }

        .dropdown {
          display: block;
          position: relative;
          z-index: 1000;

          .dropbtn {
            background: #ff9900;
            color: white;
            width: 9.5rem;
            height: 3.5rem;
            font-size: 1.6rem;
            font-weight: 300;
            border: none;
            cursor: pointer;
            text-transform: uppercase;
          }

          .dropdown-content {
            position: absolute;
            background-color: #f1f1f1;
            min-width: 9.5rem;
            padding: 0.5rem 0rem;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            cursor: pointer;

            a {
              color: black;
              padding: 1rem 1.2rem;
              text-decoration: none;
              display: block;
            }
          }
        }

        a {
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #707c8c;
        }
        a:hover {
          color: #009900;
        }
      }
      .nav-btn {
        width: 9.5rem;
        height: 3.5rem;
        background: #ff9900;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          color: #ffffff !important;
        }
      }
      .nav-items .active {
        color: #009900;
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .nav {
    width: 100%;
    height: 7.5rem;
    background: #ffffff;
    display: flex;

    .navbar {
      width: 90%;
      height: 4.5rem;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-logo {
        font-weight: 700;
        font-size: 2rem;
        line-height: 175.02%;

        a {
          text-decoration: none;
          color: #ff9900;
        }
      }

      .hamburger-menu {
        label {
          font-size: 3rem;
        }

        input {
          display: none;
        }
      }

      .nav-items {
        display: none;
      }
    }
  }
}
