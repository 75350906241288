// Media Queries

// IPads or Tablets

@media (min-width: 767px) {
  .paralink-container {
    margin-bottom: 10rem;
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .paralink-container {
    margin-bottom: 10rem;
  }
}
