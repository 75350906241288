// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .footer {
    width: 100%;
    height: 7.8rem;
    background: #003b86;
    display: flex;

    .foot-content {
      max-width: 117.6rem;
      width: 90%;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .foot-logo {
        width: 16rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          text-decoration: none;
        }

        p {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 175.02%;
          color: #ffffff;
        }

        img {
          width: 3.5rem;
          height: 3.5rem;
        }
      }

      .foot-items {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        width: 32rem;

        .foot-item a {
          color: #ffffff;
          font-weight: 300;
          font-size: 1.6rem;
          line-height: 175.02%;
          text-decoration: none;
          text-transform: capitalize;
        }
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .footer {
    width: 100%;
    height: 100%;
    background: #003b86;
    display: flex;
    padding: 3.3rem 8rem;

    .foot-content {
      width: 20rem;
      margin: auto;
      align-items: center;

      .foot-logo {
        width: 19.5rem;
        display: flex;
        justify-content: space-between;
        margin: auto;
        align-items: center;

        a {
          text-decoration: none;
        }

        p {
          font-weight: 400;
          font-size: 2.4rem;
          line-height: 175.02%;
          color: #ffffff;
        }
      }

      .foot-items {
        text-align: center;
        list-style-type: none;
        width: 100%;

        .foot-item a {
          color: #ffffff;
          font-weight: 400;
          font-size: 2rem;
          line-height: 175.02%;
          text-decoration: none;
          text-transform: capitalize;
        }
      }
    }
  }
}
