@media (min-width: 768px) {
  .event-container {
    width: 117.6rem;
    margin: auto;
  }
  .event-container .evntcard-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5.4rem;
  }
  .event-container .actcard-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8.8rem;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .event-container {
    width: 36rem;
    margin: auto;
  }
  .event-container .actcard-container {
    margin-bottom: 8.8rem;
  }
}/*# sourceMappingURL=Event.css.map */