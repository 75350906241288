// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .paralink-details {
    max-width: 117.6rem;
    height: 100%;
    width: 100%;
    margin: auto;

    .paralink-title {
      align-items: baseline;
      margin-bottom: 1.6rem;

      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 175.02%;
        color: #003b86;
        // text-decoration: underline;
        // text-decoration-color: #009900;
        // text-underline-offset: 0.7rem;
      }

      .paralink-dash {
        width: 9rem;
        height: 0.3rem;
        background: #009900;
        border-radius: 0.5rem;
      }
    }

    .paralink-para {
      font-weight: 400;
      font-size: 16px;
      line-height: 175.02%;
      color: #707c8c;
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .paralink-details {
    width: 70%;
    min-width: 32rem;
    height: 100%;
    margin: auto;

    .paralink-title {
      // display: flex;
      align-items: baseline;
      margin-bottom: 1.6rem;

      h3 {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 175.02%;
        color: #003b86;
      }

      .paralink-dash {
        width: 9rem;
        height: 0.3rem;
        background: #009900;
        border-radius: 0.5rem;
      }
    }

    .paralink-para {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 155%;
      color: #707c8c;
    }
  }
}
