// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .alsceme {
    background-image: url("/public/images/pattern.png");
    background-size: cover;
    width: 141.6rem;
    height: 54.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9.4rem auto 6rem auto;

    .alsceme-box {
      max-width: 117.6rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 20rem auto auto auto;
      background-size: cover;
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .alsceme {
    width: 36rem;
    margin: 9.4rem auto 6rem auto;

    .alsceme-box {
      max-width: 100%;
      margin: auto;
    }
  }
}
