// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .mamta {
    width: 100%;
    height: 75.7rem;
    background: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;

    .mamta-box {
      width: 98.4rem;
      display: flex;
      justify-content: space-between;

      .mamta-details {
        background-image: url("/public/images/mamata.png");
        background-repeat: no-repeat;
        width: 48rem;
        height: 64.7rem;

        .mamta-text {
          position: relative;
          bottom: -30rem;

          p {
            width: 36.7rem;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 2.9rem;
            color: #009900;
            margin-bottom: 3rem;
            margin-left: 3.8rem;
          }

          .slogan {
            width: 30.8rem;
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-left: 18rem;

            p {
              font-weight: 600;
              font-size: 3.2rem;
              line-height: 3.9rem;
              color: #009900;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .mamta {
    width: 100%;
    height: 100%;
    background: #d9d9d9;
    margin-bottom: 5rem;
    padding: 3rem 0rem;

    .mamta-box {
      width: 100%;
      text-align: center;

      .mamta-details {
        background-image: url("/public/images/mamata.png");
        background-size: cover;
        width: 32rem;
        height: 43.133rem;
        margin: auto;

        .mamta-text {
          position: relative;
          bottom: -18rem;

          p {
            width: 30.6rem;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: #009900;
            margin-bottom: 3rem;
          }

          .slogan {
            width: 23.3rem;
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-left: 10rem;

            p {
              font-weight: 600;
              font-size: 2rem;
              line-height: 2.4rem;
              color: #009900;
            }
          }
        }
      }
    }
  }
}
