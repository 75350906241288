// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .evnt-card {
    width: 57.6rem;
    height: 20rem;
    border: 0.1rem solid #707c8c;
    padding: 1.4rem 2rem;
    background: #ffffff;
    margin-bottom: 2rem;

    .evntcard-title {
      font-weight: 600;
      font-size: 2rem;
      line-height: 175.02%;
      color: #707c8c;
      margin-bottom: 1.8rem;
    }
    a {
      text-decoration: underline;
      color: #707c8c;
    }

    .evntcard-para {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 130.02%;
      color: #707c8c;
      margin-bottom: 3rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .evntcard-lower {
      width: 53.3rem;
      display: flex;
      justify-content: space-between;

      .evntcard-logo {
        display: flex;
        align-items: center;

        img {
          margin-right: 0.5rem;
        }

        p {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 175.02%;
          color: #707c8c;
        }
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .evnt-card {
    width: 32rem;
    height: 100%;
    border: 0.1rem solid #707c8c;
    padding: 2.5rem 1.5rem;
    background: #ffffff;
    margin-bottom: 4rem;

    a {
      text-decoration: none;
    }

    .evntcard-title {
      font-weight: 600;
      font-size: 2rem;
      line-height: 150%;
      color: #707c8c;
      margin-bottom: 1.5rem;
    }
    a {
      text-decoration: underline;
      color: #707c8c;
    }

    .evntcard-para {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 175.02%;
      color: #707c8c;
      margin-bottom: 2.6rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .evntcard-lower {
      .evntcard-logo {
        display: flex;
        align-items: center;

        img {
          margin-right: 0.5rem;
        }

        p {
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 175.02%;
          color: #707c8c;
        }
      }
    }
  }
}
