// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .event_para_active {
    margin: 8.5rem auto 25rem auto;
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .event_para_active {
    margin: 5rem auto 18rem auto;
  }
}
