@media (min-width: 767px) {
  .modalbar {
    border: 0.1rem solid #282828;
    width: 44rem;
    height: 50rem;
    padding: 2rem;
    background: white;
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .modalbar h2 {
    text-align: center;
  }
  .modalbar img {
    width: 40rem;
    height: 40rem;
  }
  .modalbar .modal_btn {
    background: #ff9900;
    width: 10rem;
    height: 3.5rem;
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
    border: none;
    margin: auto;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .modalbar {
    width: 28rem;
    height: 33rem;
    position: absolute;
    border: 0.1rem solid #282828;
    padding: 2rem;
    background: white;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000;
  }
  .modalbar h2 {
    text-align: center;
  }
  .modalbar img {
    width: 24rem;
    height: 24rem;
  }
  .modalbar .modal_btn {
    background: #ff9900;
    width: 10rem;
    height: 3.5rem;
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
    border: none;
    margin: auto;
  }
}/*# sourceMappingURL=Modal.css.map */