.paginate {
  width: 18.5rem;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 1rem;
}
.paginate img {
  cursor: pointer;
}
.paginate .paginate-text {
  width: 9rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 1rem;
}
.paginate .paginate-text p {
  font-family: Metropolis;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #003b86;
}
.paginate .paginate-text .paginate-num {
  width: 16px;
  height: 25px;
  border-radius: 2rem;
  color: #3e4756;
  position: relative;
  left: 0.3rem;
}/*# sourceMappingURL=Paginate.css.map */