// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .active-container {
    width: 117.6rem;
    margin: auto;

    .activecard-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 3rem;
    }
  }
  .paginate-content {
    margin-bottom: 5rem;
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .active-container {
    width: 32rem;
    margin: auto;

    .activecard-container {
      margin-bottom: 5.4rem;
    }
  }
}
