// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .event-container {
    width: 117.6rem;
    margin: auto;

    .evntcard-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 5.4rem;
    }

    .actcard-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8.8rem;
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .event-container {
    width: 36rem;
    margin: auto;

    .actcard-container {
      margin-bottom: 8.8rem;
    }
  }
}