// Media Queries

//  Computers and Laptops

@media (min-width: 768px) {
  .volprofile {
    margin: 3.6rem auto 15.3rem auto;
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .volprofile {
    width: 32rem;
    margin: 3.6rem auto 15.3rem auto;
  }
}