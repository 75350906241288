// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .paralink-contact {
    margin-bottom: 20rem;
    // margin-left: 31rem;
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .paralink-contact {
    margin-bottom: 20rem;
  }
}
