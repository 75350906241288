// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .profile {
    width: 69.6rem;
    height: 31.7rem;
    margin: auto;
    text-align: center;

    img {
      width: 15rem;
      height: 15rem;
    }

    .profile-bio {
      font-weight: 400;
      font-size: 2rem;
      line-height: 190.02%;
      text-align: center;
      color: #707c8c;
      margin-bottom: 1.5rem;
    }

    .profile-name {
      font-weight: 600;
      font-size: 2rem;
      line-height: 24px;
      text-align: center;
      color: #003b86;
      margin-bottom: 1.5rem;
    }

    .profile-job {
      width: 46.1rem;
      display: flex;
      justify-content: space-between;
      margin: auto;

      p {
        color: #003b86;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .profile {
    width: 36rem;
    height: 100%;
    margin: auto;
    text-align: center;

    img {
      width: 15rem;
      height: 15rem;
    }

    .profile-bio {
      width: 32rem;
      font-weight: 400;
      font-size: 20px;
      line-height: 190.02%;
      text-align: center;
      color: #707c8c;
      margin: auto;
      margin-bottom: 1.5rem;
    }

    .profile-name {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #003b86;
      margin-bottom: 1.5rem;
    }

    .profile-job {
      width: 32rem;
      justify-content: space-between;
      margin: auto;

      p {
        color: #003b86;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}
