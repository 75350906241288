// Media Queries

// IPads or Tablets

// #####  Android Mobile  ##### //

@media (max-width: 600px) and (min-width: 501px) {
  .menu {
    width: 28.4rem;
    height: 86rem;
    padding: 0rem 3rem 0rem 3rem;
    background: #ffff;
    border: 0.1rem solid black;
    position: absolute;
    top: 4.4rem;
    left: -44rem;
    z-index: 1000;

    .menu-back {
      display: flex;
      justify-content: space-between;
      width: 22.4rem;
      margin-bottom: 2.6rem;
      margin-top: 3rem;
      margin: 3rem auto 2.6rem auto;
      text-align: center;

      .menu-text {
        font-style: normal;
        font-weight: 400;
        font-size: 2.1rem;
        line-height: 2.5rem;
        color: #000000;
      }
    }

    hr {
      width: 22.4rem;
      border: 0.1rem solid #757575;
      margin: 0rem auto 4rem auto;
    }

    .menu-items {
      height: 16.8rem;
      .menu-item {
        display: flex;
        margin-bottom: 2.5rem;

        img {
          width: 2.5rem;
        }

        .dropdown {
          display: block;
          position: relative;
          z-index: 1000;
          .dropbtn {
            background: #ff9900;
            color: white;
            width: 9.5rem;
            height: 3.5rem;
            font-size: 1.6rem;
            font-weight: 600;
            border: none;
          }

          .dropdown-content {
            position: absolute;
            background-color: #f1f1f1;
            min-width: 9.5rem;
            padding: 0.5rem 0rem;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;

            a {
              color: black;
              padding: 1rem 1.2rem;
              text-decoration: none;
              display: block;
            }
          }
        }

        li a {
          list-style-type: none;
          font-weight: 400;
          font-size: 2rem;
          line-height: 24px;
          color: #000000;
          text-decoration: none;
          position: relative;
          left: 2rem;
        }
      }
    }
  }
}

@media (max-width: 500px) and (min-width: 360px) {
  .menu {
    width: 28.4rem;
    height: 86rem;
    padding: 0rem 3rem 0rem 3rem;
    background: #ffff;
    border: 0.1rem solid #8d8d8d8d;
    position: absolute;
    top: 0rem;
    left: 0rem;
    z-index: 1000;

    .menu-back {
      display: flex;
      justify-content: space-between;
      width: 22.4rem;
      margin-bottom: 2.6rem;
      margin-top: 3rem;
      margin: 3rem auto 2.6rem auto;
      text-align: center;

      .menu-text {
        font-style: normal;
        font-weight: 400;
        font-size: 2.1rem;
        line-height: 2.5rem;
        color: #000000;
      }
    }

    hr {
      width: 22.4rem;
      border: 0.1rem solid #757575;
      margin: 0rem auto 4rem auto;
    }

    .menu-items {
      height: 16.8rem;

      .menu-item {
        display: flex;
        margin-bottom: 2.5rem;

        img {
          width: 2.5rem;
        }

        .dropdown {
          display: block;
          position: relative;
          left: 2rem;
          z-index: 1000;
          .dropbtn {
            background: #ff9900;
            color: white;
            width: 11rem;
            height: 3.5rem;
            font-size: 1.6rem;
            font-weight: 600;
            border: none;
          }

          .dropdown-content {
            // position: absolute;
            background-color: #f1f1f1;
            min-width: 9.5rem;
            padding: 0.5rem 0rem;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;

            a {
              color: black;
              padding: 1rem 0rem;
              text-decoration: none;
              display: block;
            }
          }
        }

        li {
          list-style-type: none;
        }

        li a {
          font-weight: 400;
          font-size: 2rem;
          line-height: 24px;
          color: #000000;
          text-decoration: none;
          position: relative;
          left: 2rem;
        }
      }
    }
  }
}
