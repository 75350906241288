@media (min-width: 768px) {
  .contacts {
    width: 76.3rem;
    height: 25.2rem;
    margin: auto;
  }
  .contacts .contact-email .email-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 175.02%;
    color: #707C8C;
  }
  .contacts .contact-email .email-content {
    position: relative;
    left: 8rem;
    top: -3rem;
  }
  .contacts .contact-email .email-content p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 175.02%;
    color: #707C8C;
  }
  .contacts .contact-place .email-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 175.02%;
    color: #707C8C;
  }
  .contacts .contact-place P {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 175.02%;
    color: #707C8C;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .contacts {
    width: 34rem;
    height: 100%;
    margin: 0rem auto 4.8rem auto;
    border: 0.1rem solid #707C8C;
    padding: 1rem;
  }
  .contacts .contact-email .email-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 175.02%;
    color: #707C8C;
  }
  .contacts .contact-email .email-content {
    position: relative;
    left: 8rem;
    top: -3rem;
  }
  .contacts .contact-email .email-content p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 175.02%;
    color: #707C8C;
  }
  .contacts .contact-place .email-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 175.02%;
    color: #707C8C;
  }
  .contacts .contact-place P {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 175.02%;
    color: #707C8C;
  }
}/*# sourceMappingURL=Contacts.css.map */