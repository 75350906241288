// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .quote {
    width: 48rem;
    height: 64.7rem;
    background: #003b86;
    padding: 4.2rem;

    .quote-box {
      border: 0.2rem solid #ffffff;
      padding: 1.9rem 5.2rem;

      p {
        font-weight: 600;
        font-size: 48px;
        line-height: 155.52%;
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 500px) and (min-width: 360px) {
  .quote {
    width: 32rem;
    height: 48.6rem;
    background: #003b86;
    padding: 2.8rem;
    margin: auto auto 2rem auto;

    .quote-box {
      border: 0.2rem solid #ffffff;
      padding: 1.9rem 3rem;

      p {
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 155.52%;
        color: #ffffff;
      }
    }
  }
}
