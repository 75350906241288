// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .signup {
    width: 41.3rem;
    height: 43.3rem;
    margin: auto;
    text-align: end;

    .signup-name p {
      font-weight: 600;
      font-size: 24px;
      line-height: 175.02%;
      color: #707C8C;
      text-align: center;
    }

    .signup-email {
      margin: 1.4rem auto auto auto;

      input {
        z-index: 5;
        font-family: Inter;
        width: 41.3rem;
        height: 5.2rem;
        outline: none;
        background: #FFFFFF;
        border: 0.1rem solid #707C8C;
        color: #707C8C;
        padding: 0rem 1.8rem;
        margin: auto;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 175.02%;
      }
    }

    .signup-message {
      margin: 1.4rem auto auto auto;

      textarea {
        z-index: 5;
        width: 41.3rem;
        height: 12rem;
        outline: none;
        background: #FFFFFF;
        border: 0.1rem solid #707C8C;
        color: #707C8C;
        padding: 0rem 1.8rem;
        margin: auto;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 175.02%;
        font-family: 'Inter';
      }
    }

    .signup-btn {
      margin: 2rem 0rem 0rem 0rem;
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .signup {
    width: 3.2rem;
    height: 40rem;
    // margin: auto;
    text-align: end;

    .signup-name p {
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 175.02%;
      color: #707C8C;
      text-align: center;
      width: 30rem;
    }

    .signup-email {
      margin: 1.4rem auto auto auto;

      input {
        z-index: 5;
        font-family: Inter;
        width: 32rem;
        height: 4rem;
        outline: none;
        background: #FFFFFF;
        border: 0.1rem solid #707C8C;
        color: #707C8C;
        padding: 0rem 1.8rem;
        margin: auto;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 175.02%;
      }
    }

    .signup-message {
      margin: 1.4rem auto auto auto;

      textarea {
        z-index: 5;
        width: 32rem;
        height: 12rem;
        outline: none;
        background: #FFFFFF;
        border: 0.1rem solid #707C8C;
        color: #707C8C;
        padding: 0rem 1.8rem;
        margin: auto;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 175.02%;
        font-family: 'Inter';
      }
    }

    .signup-btn {
      margin: 2rem 0rem 0rem 0rem;
    }
  }
}