// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .scheme {
    width: 36rem;
    height: 10rem;
    padding: 1.7rem 4rem;
    border: 0.1rem solid #707c8c;
    background: #ffffff;
    text-align: center;
    margin-bottom: 4rem;

    .scheme-title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: #003b86;
      margin-bottom: 1.7rem;
    }

    .scheme-para {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #707c8c;
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .scheme {
    width: 32rem;
    height: 8rem;
    padding: 1.5rem 4rem;
    border: 0.1rem solid #707c8c;
    background: #ffffff;
    text-align: center;
    margin: auto auto 2rem auto;

    .scheme-title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: #003b86;
      margin-bottom: 0.9rem;
    }

    .scheme-para {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #707c8c;
    }
  }
}
