@media (min-width: 768px) {
  .footer {
    width: 100%;
    height: 7.8rem;
    background: #003b86;
    display: flex;
  }
  .footer .foot-content {
    max-width: 117.6rem;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .footer .foot-content .foot-logo {
    width: 16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer .foot-content .foot-logo a {
    text-decoration: none;
  }
  .footer .foot-content .foot-logo p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 175.02%;
    color: #ffffff;
  }
  .footer .foot-content .foot-logo img {
    width: 3.5rem;
    height: 3.5rem;
  }
  .footer .foot-content .foot-items {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    width: 32rem;
  }
  .footer .foot-content .foot-items .foot-item a {
    color: #ffffff;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 175.02%;
    text-decoration: none;
    text-transform: capitalize;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .footer {
    width: 100%;
    height: 100%;
    background: #003b86;
    display: flex;
    padding: 3.3rem 8rem;
  }
  .footer .foot-content {
    width: 20rem;
    margin: auto;
    align-items: center;
  }
  .footer .foot-content .foot-logo {
    width: 19.5rem;
    display: flex;
    justify-content: space-between;
    margin: auto;
    align-items: center;
  }
  .footer .foot-content .foot-logo a {
    text-decoration: none;
  }
  .footer .foot-content .foot-logo p {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 175.02%;
    color: #ffffff;
  }
  .footer .foot-content .foot-items {
    text-align: center;
    list-style-type: none;
    width: 100%;
  }
  .footer .foot-content .foot-items .foot-item a {
    color: #ffffff;
    font-weight: 400;
    font-size: 2rem;
    line-height: 175.02%;
    text-decoration: none;
    text-transform: capitalize;
  }
}/*# sourceMappingURL=Footer.css.map */