// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .header {
    height: 56.8rem;
    width: 100%;
    margin-bottom: 2.5rem;

    .head {
      max-width: 117.6rem;
      width: 90%;
      margin: auto;

      .head-logo {
        width: 43.8rem;
        height: 12.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 2.9rem;
          color: #009900;
        }
      }
    }

    .head-city {
      width: 100%;
      height: 43.2rem;
      background-size: cover;

      .head-cont {
        max-width: 117.6rem;
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin: auto;

        .head-text {
          display: flex;
          align-items: center;
          width: 78.5rem;
          height: 43.2rem;

          p {
            font-weight: 700;
            font-size: 6.4rem;
            line-height: 7.7rem;
            color: #ffffff;
          }
        }

        img {
          width: 44.609rem;
          height: 56.8rem;
          position: relative;
          bottom: 13.8rem;
        }
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .header {
    height: 34.2rem;
    width: 100%;
    margin-bottom: 2.5rem;

    .head {
      width: 36rem;
      height: 7.9rem;
      margin: auto;

      .head-logo {
        width: 33.7rem;
        height: 4.841rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;

        p {
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2rem;
          color: #009900;
        }

        img {
          width: 4.5rem;
          height: 4.841rem;
        }
      }
    }

    .head-city {
      width: 100%;
      height: 17.6rem;
      background-size: cover;
      margin-top: 3rem;

      .head-cont {
        width: 90%;
        height: 100%;
        margin: auto;
        display: flex;

        .head-text {
          display: flex;
          align-items: center;
          margin: auto;
          width: 32rem;
          height: 17.6rem;

          p {
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 2.7rem;
            color: #ffffff;
          }
        }

        .head-loak {
          width: 17.3rem;
          height: 23.2rem;
          margin: auto;
          position: relative;
          top: -5.6rem;
        }

        img {
          width: 17.3rem;
          height: 23.2rem;
        }
      }
    }
  }
}
