@media (min-width: 768px) {
  .active-container {
    width: 117.6rem;
    margin: auto;
  }
  .active-container .activecard-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
  .paginate-content {
    margin-bottom: 5rem;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .active-container {
    width: 32rem;
    margin: auto;
  }
  .active-container .activecard-container {
    margin-bottom: 5.4rem;
  }
}/*# sourceMappingURL=Gallery.css.map */