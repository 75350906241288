// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .evntcard-container {
    width: 117.6rem;
    display: flex;
    justify-content: space-between;
    margin: auto;
    flex-wrap: wrap;
  }

  .button {
    width: 15rem;
    margin: 2rem auto;
  }
  .button1 {
    width: 15rem;
    margin: 2rem auto 10rem auto;
  }

  .actcard-container {
    width: 117.6rem;
    display: flex;
    justify-content: space-between;
    margin: auto;
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .evntcard-container {
    width: 32rem;
    margin: auto;
  }

  .button {
    width: 15rem;
    margin: 2rem auto;
  }
  .button1 {
    width: 15rem;
    margin: 2rem auto 10rem auto;
  }

  .actcard-container {
    width: 32rem;
    margin: auto;
  }
}
