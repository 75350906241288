@media (min-width: 768px) {
  .header {
    height: 56.8rem;
    width: 100%;
    margin-bottom: 2.5rem;
  }
  .header .head {
    max-width: 117.6rem;
    width: 90%;
    margin: auto;
  }
  .header .head .head-logo {
    width: 43.8rem;
    height: 12.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header .head .head-logo p {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.9rem;
    color: #009900;
  }
  .header .head-city {
    width: 100%;
    height: 43.2rem;
    background-size: cover;
  }
  .header .head-city .head-cont {
    max-width: 117.6rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
  .header .head-city .head-cont .head-text {
    display: flex;
    align-items: center;
    width: 78.5rem;
    height: 43.2rem;
  }
  .header .head-city .head-cont .head-text p {
    font-weight: 700;
    font-size: 6.4rem;
    line-height: 7.7rem;
    color: #ffffff;
  }
  .header .head-city .head-cont img {
    width: 44.609rem;
    height: 56.8rem;
    position: relative;
    bottom: 13.8rem;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .header {
    height: 34.2rem;
    width: 100%;
    margin-bottom: 2.5rem;
  }
  .header .head {
    width: 36rem;
    height: 7.9rem;
    margin: auto;
  }
  .header .head .head-logo {
    width: 33.7rem;
    height: 4.841rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  .header .head .head-logo p {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #009900;
  }
  .header .head .head-logo img {
    width: 4.5rem;
    height: 4.841rem;
  }
  .header .head-city {
    width: 100%;
    height: 17.6rem;
    background-size: cover;
    margin-top: 3rem;
  }
  .header .head-city .head-cont {
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
  }
  .header .head-city .head-cont .head-text {
    display: flex;
    align-items: center;
    margin: auto;
    width: 32rem;
    height: 17.6rem;
  }
  .header .head-city .head-cont .head-text p {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.7rem;
    color: #ffffff;
  }
  .header .head-city .head-cont .head-loak {
    width: 17.3rem;
    height: 23.2rem;
    margin: auto;
    position: relative;
    top: -5.6rem;
  }
  .header .head-city .head-cont img {
    width: 17.3rem;
    height: 23.2rem;
  }
}/*# sourceMappingURL=Header.css.map */