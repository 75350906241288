// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .gallery-card {
    width: 36rem;
    height: 37.6rem;
    border: 0.1rem solid #707c8c;
    margin-bottom: 4.2rem;
    position: relative;

    .gallerycard-img {
      width: 35.8rem;
      height: 20rem;

      img {
        width: 35.8rem;
        height: 20rem;
      }
    }

    .gallerycard-content {
      padding: 0.8rem 1.5rem 1.6rem 1.5rem;
      height: 17.6rem;

      .gallerycard-title {
        font-weight: 500;
        font-size: 2rem;
        line-height: 175.02%;
        color: #707c8c;
        margin-bottom: 1.6rem;
        // text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      a {
        text-decoration: underline;
        color: #707c8c;
      }

      .gallerycard-para p {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #707c8c;
        width: 32.1rem;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .gallerycard-read {
        a {
          font-weight: 500;
          color: #003b86;
          font-size: 1.4rem;
          line-height: 1.7rem;
          text-decoration: none;
        }
      }

      .gallerycont {
        display: flex;
        position: absolute;
        bottom: 1rem;
        right: 2.8rem;

        p {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 175.02%;
          color: #009900;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .gallery-card {
    width: 32rem;
    height: 37.6rem;
    border: 0.1rem solid #707c8c;
    margin-bottom: 4rem;
    position: relative;

    .gallerycard-img {
      width: 31.8rem;
      height: 20rem;

      img {
        width: 31.8rem;
        height: 20rem;
      }
    }

    .gallerycard-content {
      padding: 0.8rem 1.5rem 1.6rem 1.5rem;
      height: 17.6rem;

      .gallerycard-title {
        font-weight: 500;
        font-size: 1.6rem;
        color: #707c8c;
        margin-bottom: 1.6rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      a {
        text-decoration: underline;
        color: #707c8c;
      }

      .gallerycard-para p {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #707c8c;
        width: 29.3rem;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .gallerycard-read {
        a {
          font-weight: 500;
          color: #003b86;
          font-size: 1.4rem;
          line-height: 1.7rem;
          text-decoration: none;
        }
      }

      .gallerycont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 1rem;
        right: 2.8rem;

        p {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 175.02%;
          color: #009900;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
