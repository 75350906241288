@media (min-width: 768px) {
  .paraarc {
    max-width: 117.6rem;
    min-width: 76.8rem;
    margin: auto;
    width: 90%;
  }
  .paraarc .arc_upper {
    display: flex;
    max-width: 117.6rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .paraarc .arc_upper h1 {
    width: 100%;
    font-weight: 500;
    font-size: 2rem;
    line-height: 175.02%;
    color: #003b86;
    margin-bottom: 2.1rem;
    text-decoration: underline;
    text-decoration-color: #009900;
    text-underline-offset: 0.4rem;
  }
  .paraarc .arc_upper .arcdate {
    width: 25rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.1rem;
  }
  .paraarc .arc_upper .arcdate .arc-date {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 175.02%;
    color: #707c8c;
    width: 100%;
    text-align: right;
  }
  .paraarc .arcpara {
    max-width: 117.6rem;
    width: 100%;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 175.02%;
    color: #707c8c;
    text-align: justify;
  }
}
@media (max-width: 767px) and (min-width: 360px) {
  .paraarc {
    min-width: 33.7rem;
    width: 90%;
    margin: -4rem auto auto auto;
  }
  .paraarc h1 {
    width: 100%;
    font-weight: 500;
    font-size: 2rem;
    line-height: 175.02%;
    color: #003b86;
    margin-bottom: 2.1rem;
    text-decoration: underline;
    text-decoration-color: #009900;
    text-underline-offset: 0.7rem;
  }
  .paraarc .arcdate {
    width: 25rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
  .paraarc .arcdate .arc-date {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 175.02%;
    color: #707c8c;
  }
  .paraarc .arcpara {
    min-width: 33.7rem;
    width: 100%;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 175.02%;
    color: #707c8c;
    text-align: justify;
  }
}/*# sourceMappingURL=ParaArc.css.map */