@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ############################## html element styling ##############################*/
html {
  font-size: 62.5%;
}

body {
  font-family: "Inter", sans-serif;
  margin: auto;
}

.strip {
  width: 100%;
  height: 1rem;
  background-color: #033b86;
}
