// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .gallery_container {
    width: 100%;
    margin: auto;

    .gallery_page {
      max-width: 117.6rem;
      width: 90%;
      margin: auto;
      .gallery_title {
        font-size: 2.5rem;
        color: #003b86;
        margin-bottom: 2.1rem;
      }
      .gallery_description {
        font-size: 1.6rem;
        color: #707c8c;
        margin-bottom: 2.1rem;
      }

      .gallery_image {
        width: 65rem;
        height: 100%;
        margin-bottom: 5rem;
        .gallery_image1 {
          width: 65rem;
          height: 100%;
          img {
            width: 65rem;
            height: 100%;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 360px) {
  .gallery_container {
    width: 36rem;
    margin: auto;

    .gallery_page {
      width: 33.7rem;
      margin: -5rem auto auto auto;
      .gallery_title {
        font-size: 2.2rem;
        font-weight: 500;
        color: #003b86;
        margin-bottom: 2.1rem;
      }
      .gallery_description {
        font-size: 1.6rem;
        color: #707c8c;
        margin-bottom: 2.1rem;
      }

      .gallery_image {
        width: 33.7rem;
        height: 100%;
        margin-bottom: 5rem;
        .gallery_image1 {
          width: 33.6rem;
          height: 100%;
          img {
            width: 33.6rem;
            height: 100%;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
