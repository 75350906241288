// Media Queries

// IPads or Tablets

@media (min-width: 768px) {
  .para-active {
    margin: 11.5rem 0rem 50rem 0rem;
  }
}

@media (max-width: 500px) and (min-width: 360px) {
  .para-active {
    margin-bottom: 50rem;
  }
}
